body {
    @apply bg-white font-light text-grey-600;
    font-size: 18px;
    font-family: 'averta', 'sans-serif';
}

.container-px {
    @apply px-8;
}

.container {
    @extend .container-px;

    @media(min-width: 1400px) {
        max-width: 1400px;
    }
}

.container--no-px-tab {
    @media(max-width: 1023px) {
        padding-left: 0;
        padding-right: 0;
        max-width: 100%;
    }
}

.container--no-px-mob {
    @media(max-width: 767px) {
        padding-left: 0;
        padding-right: 0;
    }
}

.section-py--sm {
    @apply py-12;

    @screen md {
        @apply py-16;
    }
}

.section-pb--sm {
    @apply pb-12;

    @screen md {
        @apply pb-16;
    }
}

.section-py--md {
    @apply py-12;

    @screen md {
        @apply py-24;
    }
}

.section-pt--md {
    @apply pt-12;

    @screen md {
        @apply pt-24;
    }
}

.section-pb--md {
    @apply pb-12;

    @screen md {
        @apply pb-24;
    }
}

.section-py--lg {
    @apply py-12;

    @screen md {
        @apply py-16;
    }

    @screen lg {
        @apply py-32;
    }
}

.row {
    @apply flex flex-wrap -mx-6;

    .col {
        @apply px-6;
    }

    &--tight {
        @apply -mx-3;

        .col {
            @apply px-3;
        }
    }

    &--tightest {
        @apply -mx-2;

        .col {
            @apply px-2;
        }
    }
}

::selection {
    background: rgba(#bbded3, 0.6);
}

::-moz-selection {
    background: rgba(#bbded3, 0.6);
}

.home-row {
    @apply py-12;

    @screen md {
        @apply py-16;
    }

    @screen lg {
        @apply py-24;
    }

    @screen xl {
        @apply py-32;
    }
}

.c-close-icon {
    @apply relative inline-block;
    width: 30px;
    height: 25px;

    &::before,
    &::after {
        @apply absolute left-0 w-full bg-grey-1000;
        content: "";
        top: 50%;
        height: 1px;
    }

    &::before {
        @apply bg-white;
        transform: translateY(-50%) rotate(45deg);
    }


    &::after {
        @apply bg-white;
        transform: translateY(-50%) rotate(-45deg);
    }
}

.no-results-img {
    @apply mx-auto mb-8;
    max-width: 250px;
    transform: translateX(10%);

    @screen md {
        max-width: 300px;
    }
}

// Confetti drop
.confetti-active {
    @for $i from 0 through 100 {
        $w: random(15);
        $l: random(100);

        .confetti--#{$i} {
            @apply fixed bg-copper-400 z-10;
            width: #{$w}px;
            height: #{$w*0.8}px;
            bottom: 110%;
            left: 50%;
            z-index: 50;
            opacity: random() + 0.5;
            transform: rotate(#{random()*360}deg);
            animation: drop-#{$i} unquote(4+random()+"s") unquote(random()+"s") 1;
        }

        @keyframes drop-#{$i} {
            100% { bottom: -10%; left: unquote($l+"%"); };
        }
    }
}


.c-image-uploader {
    &__image {
        @apply w-32 h-32 rounded-full bg-gray-500 relative bg-cover;
        background-image: url('/images/backgrounds/default-profile-mobile.jpg');
        @screen md {
            @apply w-48 h-48;
            background-image: url('/images/backgrounds/default-profile-mobile.jpg');
            
        }
        @screen lg {
            background-image: url('/images/backgrounds/default-profile.jpg');

        }

        &--processing {
            background-image: url('/images/backgrounds/profile-spinner.gif');
        }
    }
}

.c-divs-as-tr {
    &__row {
        @apply bg-white py-2;

        &:nth-of-type(odd) {
            @apply bg-grey-200;
        }
    }
}


// Error pages
.system-error-feedback {
    @apply flex flex-col items-center justify-center flex-wrap shadow-lg-allsides w-full h-full p-4;
    max-height: 80vh;
    max-width: 100%;

    @screen md {
        @apply p-8;
        min-width: 300px;
        min-height: 300px;
        max-width: 450px;
        max-height: 450px;
    }

    &__code {
        @apply mb-0;
    }

    &__message {

    }
}


// Animations
.fade-enter-active, .fade-leave-active {
    transition: opacity 1s;
}

.fade-enter, .fade-leave-to {
    opacity: 0;
}
