.header { 
    @apply fixed top-0 left-0 w-full flex items-center justify-between bg-white px-3 py-2 shadow-lg;
    z-index: 51;
    
    @screen md {
        @apply relative block px-0 py-0;
    }
}

.header-logo {
    display: none!important;

    @screen lg {
        display: block!important;
    }

    @screen xl {
        width: 300px;
    }
}

.header-cta {
    @apply hidden w-auto text-right -mt-1;

    @screen lg {
        @apply block;
    }

    @screen xl {
        min-width: 300px;
    }

    .btn {
        @apply mt-1 capitalize;
        min-height: 45px;
    }
}

.header-logo {
    @apply hidden flex-grow-0 flex-shrink;

    @screen lg {
        @apply inline-block;
    }
}

.mobile-header-logo {
    max-width: 190px;

    @screen md {
        max-width: 250px;
    }
}

.demo-btn {
    @apply hidden; 

    @screen lg {
        @apply inline-block;
    }
}