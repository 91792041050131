/* purgecss start ignore */

.c-datepicker .c-header {
    box-shadow: none !important;
}

.c-datepicker .c-title {
    @apply uppercase;
    color: #231f20!important;
    font-weight: 600!important;
    font-size: 0.875rem!important;
}

.c-datepicker .c-pane-container {
    width: 100%!important;
    font-family: 'averta'!important;
    font-weight: 300!important;
    font-size: 0.875rem!important;
    border: none!important;

    @screen md {
        width: auto!important;
    }

    .c-data-table div {
        width: auto !important;
    }
}

.c-datepicker .c-pane {
    color: #999999!important;
    background-color: #ffffff!important;
}

.c-datepicker .c-weekdays {
    display: none!important;
}

.c-datepicker .c-day:hover .c-day-background {
    background-color: #f2f2f2!important;
}

.c-datepicker .c-day-background {
    color: #231f20!important;
    background-color: #bbded3!important;
}

.c-datepicker .c-day-content div {
    color: #231f20!important;
}

/* purgecss end ignore */