.c-ambassador-preview {
    &__image {
        @apply relative mb-6;

        &::before {
            @apply absolute top-0 left-0 w-full h-full;
            content: "";
            background: linear-gradient(to top,  rgba(0,0,0,0.8) 0%,rgba(5,2,2,0) 15%);
        }

        p {
            @apply absolute text-center mb-0;
            bottom: 1rem;
            left: 50%;
            transform: translateX(-50%);
            width: calc(100% - 4rem);
            z-index: 1;
        }
    }

    &__text {
        @apply px-8 text-center text-grey-1000;
    }
}

.ambassador-wrapper {
    @apply flex flex-wrap;
}

.c-ambassador-image {
    @apply w-full bg-teal-400 overflow-hidden;

    @screen lg {
        @apply w-6/12;
        max-height: 80vh;
    }

    img {
        @apply flex-grow-0 flex-shrink mx-auto;

        @screen md {
            // max-width: auto;
            // max-height: 100%;
            min-width: 100%;
            min-height: 100%;
        }
    }
}

.c-ambassador-content {
    @apply w-full flex-shrink-0 px-8 ;

    @screen lg {
        @apply px-16 overflow-y-auto;
        height: 80vh;
    }

    @screen lg {
        @apply w-6/12;
    }
}


/* purgecss start ignore */

.testimonial-carousel {
    width: 100%;
}

.testimonial-slide {
    width: 100%;
    flex-direction: row;


    .title {
        color: #fefefe;
    }

    .body {
        font-family: Averta;
        font-weight: 300;
        font-style: italic;
        color: #CCCBCC;
    }

    .image {
        overflow: hidden;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        min-width: 100px;
        // width: 100px;
        // height: 100px;

        img {
            border-radius: 50%;
            width: 100px;
            height: auto;
            object-fit: contain;
        }
    }
}

.testimonial-text {
    padding: 0px 20px;
    flex: 1;
}

// .testimonial-carousel.no-pager>.VueCarousel-pagination {
//     display: none;
// }


.testimonial-carousel>.VueCarousel-pagination {
    padding-left: 120px;
    text-align: left !important;
    .VueCarousel-dot-container {
        margin-left: 0;
        margin-top: 0 !important;
        &>.VueCarousel-dot {
            background-color: transparent !important;
            border: 1px solid #CBCBCB;
            margin-right: 5px;
            padding: 0 !important;
            width: 12px !important;
            height: 12px !important;
            background-clip: border-box !important;



            &.VueCarousel-dot--active {
                background-color: white !important;
                border-color: white;
            }
        }
    }
}

.testimonials-wrapper {
    text-align: left;
}

.testimonial-header {
    text-align: left;
}

@media (max-width: 1024px) {

    .testimonial-header {
        text-align: center;
    }

    .testimonials-wrapper {
        text-align: center;
    }

    .testimonial-slide {
        flex-direction: column;
        align-items: center;
        justify-content: start;
    }



    .testimonial-carousel>.VueCarousel-pagination {
        text-align: center !important;
        padding-left: 0;

        &>.VueCarousel-dot-container>.VueCarousel-dot {
            margin-right: 10px;
            width: 15px !important;
            height: 15px !important;
        }
    }
}

/* purgecss end ignore */
