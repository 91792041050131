.circle-bg {
    @apply relative;

    &::before {
        @apply absolute bg-grey-200 rounded-full;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "";
        height: 80vw;
        width: 80vw;

        @screen md {
            height: 50vw;
            width: 50vw;
            min-height: 500px;
            min-width: 500px;
        }

        @screen lg {
            min-height: 650px;
            min-width: 650px;
        }

        @screen xl {
            height: 30vw;
            width: 30vw;
            min-height: 800px;
            min-width: 800px;
        }
    }

    &--sm::before {
        height: 80vw;
        width: 80vw;

        @screen md {
            height: 50vw;
            width: 50vw;
            min-height: 300px;
            min-width: 300px;
        }

        @screen lg {
            min-height: 450px;
            min-width: 450px;
        }

        @screen xl {
            height: 20vw;
            width: 20vw;
            min-height: 650px;
            min-width: 650px;
        }
    }

    &--lg::before {
        @screen md {
            height: 50vw;
            width: 50vw;
            min-height: 500px;
            min-width: 500px;
        }

        @screen lg {
            min-height: 650px;
            min-width: 650px;
        }

        @screen xl {
            min-height: 950px;
            min-width: 950px;
        }
    }
}