.c-primary-nav {
    @apply fixed bottom-0 left-0 w-full flex justify-between items-center py-1 px-8 bg-grey-300 text-black text-2xl z-40;
} 

.c-burger-icon {
    @apply absolute block w-full bg-grey-1000;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    transition: 0.4s ease;

    &::before,
    &::after {
        @apply absolute left-0 w-full bg-grey-1000;
        content: "";
        height: 2px;
        transition: 0.4s ease;
    }

    &::before {
        top: -8px;
    }

    &::after {
        bottom: -8px;
    }

    &.active {
        @apply bg-transparent;

        &::before {
            @apply top-0 bg-white;
            transform: translateY(-50%) rotate(45deg);
        }

        &::after {
            @apply bottom-0 bg-white;
            transform: translateY(-50%) rotate(-45deg);
        }
    }
}