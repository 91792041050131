// Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~@fortawesome/fontawesome-pro/css/all.css';

@import './mixins';
@import './fonts';
@import './base';
@import './misc';
@import './buttons';

/* purgecss start ignore */
[v-cloak] {
    @apply hidden;
}


 body, #app, main {
    width: 100vw;
    min-height: 100vh;
    overflow-y: hidden;
    min-height: 100vh;
//    height: 100vh;
}

html {
    height: 100vh;
    overflow-y: auto;
}

/* purgecss end ignore */
