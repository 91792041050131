.c-popup-drawer {
    @apply fixed bg-transparent w-full bottom-0 shadow-lg-allsides text-grey-1000 font-normal;
    z-index: 51;
    overflow-y: visible;

    &::before {
        @apply fixed top-0 left-0 w-screen h-screen bg-black pointer-events-auto opacity-0;
        content: "";
        animation: fadein 1s 0.4s normal forwards ease-in-out;
        z-index: 50;
        @keyframes fadein {
            0% { opacity: 0; }
            100% { opacity: .80; }
        }
    }

    &__inner {
        @apply overflow-visible pt-12 p-4 bg-white relative;
        z-index: 60;

        @screen xs {
            @apply p-8;
        }

        > div {
            @apply overflow-y-auto;
            max-height: 70vh;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;

            @screen md {
                max-height: 80vh;
            }
        }
    }
}

.c-drawer-close {
    @apply absolute top-0 flex items-center justify-center text-grey-400 bg-white shadow-lg-allsides rounded-full font-normal;
    min-width: 40px;
    min-height: 40px;
    left: 50%;
    transform: translate(-50%,-50%);
    line-height: 0;
    transition: color 0s, 0.4s ease;
    z-index: 100;
    overflow: visible;

    &__icon {
        @apply relative font-light text-3xl;
        transition: 0.2s 0.2s ease;
    }

    &__tooltip {
        @apply opacity-0 underline;
        width: 0;
        transform: scale(0);
        transition: opacity 0.2s ease, transform 0.2s ease, width 0s 0.2s ease;
    }

    &:focus {
        @apply outline-none;
    }

    &:hover {
        @apply py-2 px-4 pr-6 bg-grey-1000 text-white;

        .c-drawer-close__icon {
            @apply opacity-0;
            width: 0;
            transition: 0s;
        }

        .c-drawer-close__tooltip {
            @apply ml-2 w-full opacity-100;
            transform: scale(1);
            transition: width 0.2s, opacity 0.2s 0.2s ease, transform 0.2s 0.2s ease;
        }
    }
}

.c-drawer-timer {
    @apply text-left font-semibold text-base pointer-events-none flex flex-col justify-center items-center mt-4;

    &__icon {
        @apply inline-block flex-shrink-0 mb-2;
        min-width: 45px;
    }

    &__tooltip {
        @apply inline-block uppercase;
        transition: 0.4s ease;
    }
}



.c-popup-search {
    @apply fixed left-0 bottom-0 w-full flex justify-center mx-auto;
    padding-top: 30px;
    height: 90vh;
    z-index: 5000;

    //If you change these max height values then you need to change .c-hero-forms--popup .c-hero-forms__form in frontend/shared/_forms to match
    @screen md {
        height: auto;
        max-height: 70vh;
    }

    @screen xl {
        max-height: 80vh;
    }

    &::before {
        @apply fixed top-0 left-0 w-screen h-screen bg-black pointer-events-auto opacity-0;
        content: "";
        z-index: 51;
        animation: fadein 1s 0.4s normal forwards ease-in;

        @keyframes fadein {
            0% { opacity: 0; }
            100% { opacity: .80; }
        }
    }

    .c-hero-forms--popup {
        z-index: 52;
    }
}

.c-popup-search.popup-slide-leave::before {
    display: none!important;
}
