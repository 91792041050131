// Social icons on contact page
.rounded-social-icon {
    @apply flex items-center justify-center text-white text-xl rounded-full;
    width: 50px;
    height: 50px;
    background-color: currentColor;
    transition: 0.4s ease;

    &--fb {
        background-color: #3b5998;

        &:hover {
            background-color: darken(#3b5998, 20%);
        }
    }

    &--insta {
        background-color: #cc1984;

        &:hover {
            background-color: darken(#cc1984, 20%);
        }
    }

    &--linkedin {
        background-color: #3b5998;

        &:hover {
            background-color: darken(#3b5998, 20%);
        }
    }

    &--twitter {
        background-color: #1da1f2;

        &:hover {
            background-color: darken(#1da1f2, 20%);
        }
    }

    &--whatsapp {
        background-color: #25d366;

        &:hover {
            background-color: darken(#25d366, 20%);
        }
    }

    &--email {        
        background-color: #c3c2c2;

        &:hover {
            background-color: darken(#c3c2c2, 20%);
        }
    }
}

// Circular image on signup page
.signup-image {
    @apply w-7/12 ml-auto;

    @screen md {
        @apply w-4/12;
    }

    @screen lg {
        @apply ml-0;
        position: absolute!important;
        top: 6rem;
        right: -5%;
    }
}

.c-rounded-icon {
    @apply flex items-center justify-center flex-shrink-0 border-2 border-solid border-teal-400 rounded-full;
    width: 70px;
    height: 70px;

    &--small {
        width: 45px;
        height: 45px;

        .c-rounded-icon__icon {
            @apply text-2xl;
        }
    }

    &__icon {
        @apply text-4xl text-grey-1000;
    }
}

// Background circles on sections
.c-section-circle-bgs {
    @apply relative overflow-hidden;

    &::before,
    &::after {
        @apply absolute bg-grey-200 rounded-full pointer-events-none;
        content: "";
        height: 100vw;
        width: 100vw;

        @screen md {
            height: 50vw;
            width: 50vw;
        }

        @screen lg {
            height: 30vw;
            width: 30vw;
        }
    }

    &::before {
        top: 10%;
        right: 0;
        transform: translateX(70%);
    }

    &::after {
        bottom: 10%;
        left: 0;
        transform: translate(-70%, -50%);
    }

    &__inner {
        @apply relative z-10;
    }
}

.hide-scrollbar {
    &::-webkit-scrollbar {
        width: 0px;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
}


// Increase the height of all back buttons across the app:

.fa-long-arrow-left[aria-label="Back"] {
    height: 30px;
    padding-top: 5px
}