h1,
.h1 {
    @apply font-bold mb-8 text-grey-1000 font-poynter;
    font-size: 2rem;
    line-height: 2.25rem;

    @screen md {
        font-size: 2.75rem;
        line-height: 3rem;
    }
}

h2,
.h2 {
    @apply font-bold mb-8 text-grey-1000 font-poynter;
    font-size: 1.75rem;
    line-height: 2.2rem;

    @screen xl {
        line-height: 2.6rem;
    }
    
    &--copper {
        @apply text-copper-400;
    }
}

h3,
.h3 {
    @apply font-bold text-grey-1000 mb-6 font-poynter;
    font-size: 1.25rem;
}

h4,
.h4 {
    @apply font-semibold text-grey-1000 mb-6;
    font-size: 1.25rem;
}

h5,
.h5 {
    @apply font-semibold uppercase text-grey-1000 mb-4;
    font-size: 1rem;
}

.label {
    @apply uppercase text-sm font-semibold;
}

strong {
    @apply font-semibold;
}

p,
ul,
ol {
    @apply mb-8;
}


.flowing-content {
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply mb-4;
    }

    p + h2,
    p + h3,
    p + h4,
    p + h5,
    p + h6 {
        @apply mt-10;
    }

    ul, ol {
        li {
            @apply mb-2;
        }
    }

    ol {
        @apply list-decimal;
        padding-left: 26px;
    }

    ul {
        @apply list-disc;
        padding-left: 19px;
    }

    a {
        @apply underline;

        &:hover {
            @apply no-underline;
        }
    }
}
