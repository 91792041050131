.c-check-list-item {
    @apply flex items-start mb-6;

    @screen md {
        @apply mb-8;
    }

    &__check {
        @apply flex items-center justify-center flex-shrink-0 bg-copper-400 text-white rounded-full mr-3;
        height: 35px;
        width: 35px;

        i {
            @apply text-base;
        }
    }

    &__text {
        @apply font-normal text-grey-1000 pt-1;
    }
}