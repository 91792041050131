.c-tag-group {
    @apply flex flex-wrap;
    margin-left: -2px;
    margin-right: -2px;

    &__item {
        @apply py-2 px-3 bg-teal-400 border border-solid border-teal-400 text-grey-1000 font-normal text-sm rounded;
        transition: 0.4s ease;
        margin: 0 2px 4px 2px;
    }
}