.c-hero-w\/form {
    @apply relative flex flex-wrap bg-teal-400 py-10 pt-16 pb-6;
    height: 80vh;

    @screen md {
        @apply py-16 pb-20;
        height: auto;
    }

    @screen 2xl {
        @apply py-20 pb-24;
    }

    &__text {
        @apply relative justify-center;
        z-index: 1;


        // @screen lg {
        //     @apply pt-16;
        // }

        // @screen xl {
        //     @apply pt-24;
        // }

        h1 {
            @media(max-width: 767px) {
                font-size: 2.25rem;
            }
        }
    }

    &__form {
        @apply relative;
        z-index: 1;
    }

    //This is its own div rather than on the section, becuase we need overflow visible on the section to allow for the form calendar to overflow if needed
    &__bgs {
        @apply absolute top-0 left-0 w-full h-full overflow-hidden;

        &::before {
            @apply absolute bg-white rounded-full pointer-events-none;
            content: "";
            top: -30%;
            left: -48%;
            height: 120vw;
            width: 150vw;

            opacity: 0.2;

            @screen md {
                top: -10%;
                left: -10%;
                height: 50vw;
                width: 50vw;
            }
        }

        &::after {
            @apply absolute bottom-0 left-0 w-full bg-cover bg-repeat-x bg-left-top;
            content: "";
            height: 20%;
            background-image: url('/images/backgrounds/brushes.png');


            @screen md {
               @apply bg-contain bg-left-bottom;
            }
        }
    }
}
