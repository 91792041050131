// Horizontal scrolling - used on listing page for favourite bar
.horizontal-scroll-track {
    @apply w-full overflow-scroll whitespace-no-wrap flex-no-wrap;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

.horizontal-scroll-x-gradient {
    @apply relative;

    &::after {
        @apply absolute top-0 right-0 h-full;
        content: "";
        width: 45px;
        background: linear-gradient(to right,  rgba(255,255,255,0.3) 0%,rgba(255,255,255,0.3) 1%,rgba(255,255,255,1) 60%,rgba(255,255,255,1) 100%);

    }

    .horizontal-scroll-track {
        padding-right: 45px;
    }
}

// Star rating - used in business header
.c-star-rating {
    @apply flex items-center justify-center bg-contain bg-center bg-no-repeat;
    background-image: url('/images/icons/star.svg');
    min-width: 60px;
    min-height: 60px;

    &__number {
        @apply relative text-white font-semibold text-base;
        top: 3px;
    }
}

// Image overlay
.c-image-overlay-gradient {
    @apply relative ;

    &::before {
        @apply absolute top-0 left-0 h-full w-full;
        content: "";
        background: linear-gradient(
            to top,
            rgba(30, 30, 30, 0.9) 0%,
            rgba(30, 30, 30, 0.8) 20%,
            rgba(30, 30, 30, 0) 40%,
        );
    }
}

// Circular images on about page
.about-image {
    @apply absolute hidden;

    @media(min-width: 1100px) {
        @apply block;
    }

    &--left {
        @apply left-0;
        top: 10%;
        transform: translateX(-65%);

        @media(min-width: 1200px) {
            transform: translateX(-50%);
        }
    }

    &--right {
        @apply right-0;
        bottom: 10%;
        transform: translateX(65%);

        @media(min-width: 1200px) {
            transform: translateX(50%);
        }
    }
}

.about-container {
    @media(min-width: 1100px) {
        max-width: 800px;
    }
}

/* purgecss start ignore */
#confetti-canvas {
    z-index: 100000;
}

/* purgecss endignore */

.c-fixed-search-trigger {
    @apply fixed left-0 flex justify-center;
    bottom: .5rem;

    &::after {
        @apply fixed bottom-0 left-0 w-screen pointer-events-none;
        content: "";
        height: 85px;
        background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.3) 99%,rgba(0,0,0,0.3) 100%);
        z-index: 9;
    }

    &__btn {
        @apply relative w-auto;
        z-index: 10;
    }
}
