.c-form {
    &__label {
        @apply label text-copper-400 mb-1;
    }

    &__legend {
        @apply font-poynter text-grey-1000 text-2xl mb-5;
    }

    &__radio {
        @apply flex;
    }
}

.c-simulated-checkbox {
    // @apply flex;

    &__input {

    }
}

label.required {
    @apply relative;
    padding-right: 10px;

    &::after {
        @apply absolute text-red-500;
        content: "*";
        top: 50%;
        transform: translateY(-50%);
    }
}


