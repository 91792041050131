.c-video-hero {
    @apply relative flex items-center bg-teal-400 py-10 bg-center bg-no-repeat bg-cover overflow-hidden;
    background-image: url('/images/video-placeholder.png');

    @screen md {
        @apply py-16;
    }
    
    @screen lg {
        min-height: 45vh;
    }

    &::before {
        @apply absolute top-0 left-0 h-full w-full bg-white;
        content: "";
        z-index: 1;
        opacity: 0.4;
    }

    &__content {
        @apply relative text-center;
        z-index: 2;

        img {
            margin-left: auto;
            margin-right: auto;
            max-width: 90%;
    
            @screen sm {
                max-width: 450px;
            }
        }
    }

    &__video {
        @apply absolute opacity-0 pointer-events-none;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        &--visible {
            @apply opacity-100;
        }
    }
}