.c-rounded-cta {
    @apply relative rounded-full;

    &::after {
        @apply block;
        content: "";
        padding-bottom: 100%;
    }

    &__inner {
        @apply absolute flex flex-col w-full p-8 text-center;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &--teal {
        @apply bg-teal-400;
    }

    &--purple {
        @apply bg-purple-400;
    }
    
    &--blue {
        @apply bg-blue-400;
    }
}