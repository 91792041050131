.c-business-nav {
    @apply mb-0;
    
    &__item {
        @apply border border-grey-1000 p-2;

        &:not(:last-of-type) {
            border-bottom: none;
        }

        a {
            @apply flex items-center justify-between;
        }
    }
}


.c-sub-page-menu {
    @apply mb-0;

    &__item {
        &:not(:last-of-type) {
            @apply border-b border-grey-100
        }

        a {
            @apply flex items-center justify-between py-4 px-8 bg-white text-grey-1000 font-bold uppercase;
        }
    }
}