.c-form {
    select,
    input,
    label {
        // @apply font-normal text-base;
    }

    display: flex;
    flex-direction: column;

    .submit {
        margin-top: auto;
    }

    &__input,
    &__select,
    &__stylised-checkbox,
    &__radio-as-toggle {
        @apply mb-4;
    }

    &__input,
    &__select {
        @apply py-3 px-4 appearance-none outline-none rounded bg-grey-200 text-grey-1000;

        &:focus {
            @apply outline-none;
        }
    }

    &__input {
        &:focus {
            &::placeholder {
                opacity: 0;
            }
        }

        &::placeholder {
            @apply text-grey-1000 text-base normal-case;
            transition: 0.2s ease;
        }

        &--white {
            @apply bg-white;
        }
    }

    &__select {
        @apply bg-no-repeat;
        background-image: url('/images/icons/select-arrow.svg');
        background-size: 8px;
        background-position: calc(100% - 1rem) center;
        padding-right: calc(1rem + 15px);
        min-width: 175px;

        &[disabled] {
            background-image:none;
        }
    }

    &__label-as-btn {
        @apply inline-block;

        input {
            @apply hidden;

            &:checked + label {
                @apply bg-grey-400 text-white;
            }
        }

        label {
            @apply block border border-grey-400 text-black text-sm font-normal mt-2 py-1 px-2 cursor-pointer;
            transition: 0.4s ease;
        }
    }

    &__stylised-checkbox {
        @apply flex relative cursor-pointer;

        input {
            @apply hidden;

            &:checked + label {
                &::after {
                    @apply opacity-100;
                }
            }
        }

        label {
            @apply cursor-pointer;
            padding-left: 36px;

            &:hover {
                &::after {
                    @apply opacity-100;
                }
            }

            &::before {
                @apply absolute inline-block bg-grey-200 mr-2;
                content: "";
                top: 3px;
                left: 0;
                height: 20px;
                width: 20px;
            }

            &::after {
                @apply absolute bg-contain bg-no-repeat bg-center opacity-0;
                content: "";
                top: 6px;
                left: 3px;
                width: 13px;
                height: 13px;
                background-image: url('/images/icons/check.svg');
                transition: 0.2s ease;
            }
        }

        &--round {
            input {
                &:checked + label {
                    &:hover {
                        &::after {
                            top: 8px;
                            left: 5px;
                            width: 10px;
                            height: 10px;
                            background-image: url('/images/icons/close.svg');
                        }
                    }

                    &::before {
                        @apply bg-teal-400;
                    }
                }
            }

            label {
                &::before {
                    @apply rounded-full;
                }

                &::after {
                    top: 7px;
                    left: 4px;
                    width: 12px;
                    height: 12px;
                }
            }
        }
    }

    &__radio-as-toggle {
        @apply flex flex-wrap;

        input {
            @apply hidden;

            &:checked + label {
                @apply bg-grey-600 text-white;
            }
        }

        label {
            @apply inline-block w-6/12 px-4 text-center uppercase text-sm font-semibold bg-grey-200 text-grey-1000 cursor-pointer;

            //Couldn't get it visually matching  to other filter elems with Tailwind
            padding-top: 0.4rem;
            padding-bottom: 0.4rem;
            &:first-of-type {
                @apply rounded-l;
            }

            &:last-of-type {
                @apply rounded-r;
            }
        }
    }

    &__checkbox-as-toggle {
        @apply relative cursor-pointer;

        &:not(.no-color-toggle):checked input + label {
            &::before {
                @apply bg-teal-400;
            }
        }
        input {
            @apply hidden;

            // Don't color it if no-color-toggle

            &:checked + label {
                &::after {
                    left: 20px;
                }
            }
        }

        label {
            @apply relative cursor-pointer;
            padding-left: 50px;

            &::before {
                @apply absolute left-0 rounded-full bg-grey-200 border-2 border-solid border-grey-400;
                content: "";
                top: 50%;
                transform: translateY(-50%);
                height: 20px;
                width: 40px;
                transition: 0.2s ease;
            }

            &::after {
                @apply absolute top-0 left-0 rounded-full bg-grey-400;
                content: "";
                height: 20px;
                width: 20px;
                transition: 0.2s ease;
            }
        }
    }

    &__auto-complete {
        @apply relative flex justify-between mb-4;

        @screen md {
            @apply block;
        }

        input {
            @apply mb-0;
        }

        .vue-simple-suggest {
            @apply w-full;
        }

        .suggestions {
            @apply absolute left-0 bg-white text-grey-1000 text-base w-full mb-0 z-50 shadow-lg-allsides;
            top: calc(100% + 0.5rem);
            max-height: 200px;
            overflow-y: auto;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;

            .suggest-item {
                @apply py-2 px-4 cursor-pointer font-normal;
                transition: 0.4s ease;

                &:hover,
                &.hover {
                    @apply bg-grey-300;
                }
            }
        }
    }

    &__location-group {
        @apply relative mb-4;

        input {
            @apply mb-0;
            padding-right: 40px;
        }

        .location-icon {
            @apply absolute top-0 right-0 h-full flex items-center justify-center;
            width: 40px;
        }
    }

    &__reset {
        @apply absolute top-0 right-0 h-full bg-no-repeat bg-center bg-transparent cursor-pointer;
        font-size: 0;
        width: 30px;
        background-size: 10px;
        background-image: url('/images/icons/close--red.svg');

        &:focus {
            @apply outline-none;
        }
    }

    &__search-group {
        @apply relative;

        .c-form__input {
            @apply mb-0;
            padding-left: 40px;
            padding-right: 30px;
        }

        .c-form__search-icon {
            @apply absolute top-0 left-0 h-full;
            width: 40px;
        }
    }

    &__search-icon {
        @apply bg-no-repeat bg-center bg-transparent cursor-pointer;
        font-size: 0;
        background-size: 18px;
        background-image: url('/images/icons/search.svg');

        &:focus {
            @apply outline-none;
        }
    }

    &__inline-error {
        @apply relative block text-sm font-normal mb-2;
        top: -0.5rem;
        color: #d84035;
    }

    &__group {
        @apply mb-8;

        legend {
            @apply mb-4 font-poynter font-bold text-grey-1000;
        }
    }
}

.c-hero-forms {
    @apply bg-white;
    // border-radius: 1.5rem;
    width: 100%;
    min-height: 100%;
    box-shadow: 0 0 5px 5px rgba(169, 169, 169, 0.3);
    display: flex;
    flex-direction: column;

    &__tab {
        @apply py-4 px-6 w-6/12 bg-grey-200 mb-0 text-center;
        transition: 0.4s ease;

        @screen md {
            @apply py-6 px-8;
        }

        &--active {
            @apply bg-white;
        }
    }

    &__form {
        @apply p-6 pt-4;
        flex: 1;


        @screen md {
            @apply p-8 pt-4;
        }
    }

    &--popup {
        @apply h-full w-full flex flex-col;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        @screen md {
            max-width: calc(100% - 4rem);
        }

        @screen xl {
            max-width: 1000px;
        }

        .c-drawer-close {
            transform: translate(-50%, 25%);
        }

        .c-datepicker {
            position: unset!important;
            margin-top: 10px;
        }

        .c-hero-forms__form {
            @apply flex-grow flex-shrink overflow-y-auto pb-0;
            -webkit-overflow-scrolling: touch;
            -ms-overflow-style: -ms-autohiding-scrollbar;

            @screen md {
                max-height: calc(70vh - 80px);
            }

            @screen xl {
                max-height: calc(80vh - 100px);
            }

            &--salon {
                .c-form__auto-complete {
                    margin-bottom: 220px;
                }
            }

            .submit {
                @apply mb-8 ;
            }
        }
    }
}

.input-error {
    @apply relative;

    input {
        @apply border-2;
        border-color: red;
    }

    &__message {
        @apply absolute bottom-0 bg-black py-1 px-2 text-xs text-white whitespace-no-wrap;
        left: 50%;
        transform: translate(-50%, calc(100% + 10px));
        border-radius: 5px;
        z-index: 1;

        &::before {
            @apply absolute top-0 bg-black;
            content: "";
            width: 7px;
            height: 7px;
            left: 50%;
            transform: translateY(-50%) rotate(45deg);
        }
    }
}

.c-simulated-checkbox {
    $this: &;
    @apply cursor-pointer;

    &__input {
        @apply hidden;

        &:checked + #{$this}__label {
            &::after {
                opacity: 100!important;
            }
        }
    }

    &__label {
        @apply relative text-black cursor-pointer;
        padding-left: 30px;

        &::before {
            @apply absolute left-0 bg-grey-300 rounded-full;
            content: "";
            top: 3px;
            height: 40px;
            width: 20px;
        }

        &::after {
            @apply absolute bg-contain bg-no-repeat bg-center opacity-0;
            content: "";
            top: 6px;
            left: 3px;
            width: 13px;
            height: 13px;
            background-image: url('/images/icons/check.svg');
            transition: .2s ease;
        }
    }
}
