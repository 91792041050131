.skeleton-image,
.skeleton-text {
    @apply relative bg-grey-200 overflow-hidden;

    &:before {
        @apply absolute top-0 left-0 h-full opacity-50;
        content: "";
        width: 35px;
        background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 47%,rgba(255,255,255,1) 53%,rgba(255,255,255,0) 100%);
        animation: shine 1s ease-out 0s infinite;
    }

    &--no-shine::before {
        @apply hidden;
    }

    &--dark {
        background-color:#efefef;
    }
}

.skeleton-text {
    @apply inline-block;
    min-height: 20px;

    &--lg {
        height: 40px;;
    }
}

@keyframes shine {
    0%   { left: 10%; }
    100% { left: 110%; }
}