// Averta
@font-face {
	font-family: 'averta';
	src: url('../../fonts/averta/averta-light.eot?#iefix') format('embedded-opentype'),
          url('../../fonts/averta/averta-light.woff') format('woff'),
          url('../../fonts/averta/averta-light.woff2') format('woff2'),
					url('../../fonts/averta/averta-light.ttf') format('truetype'),
          url('../../fonts/averta/averta-light.svg#averta-light') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'averta';
	src: url('../../fonts/averta/averta-regular.eot?#iefix') format('embedded-opentype'),
          url('../../fonts/averta/averta-regular.woff') format('woff'),
          url('../../fonts/averta/averta-regular.woff2') format('woff2'),
					url('../../fonts/averta/averta-regular.ttf') format('truetype'),
          url('../../fonts/averta/averta-regular.svg#averta-regular') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'averta';
	src: url('../../fonts/averta/averta-semibold.eot?#iefix') format('embedded-opentype'),
          url('../../fonts/averta/averta-semibold.woff') format('woff'),
          url('../../fonts/averta/averta-semibold.woff2') format('woff2'),
					url('../../fonts/averta/averta-semibold.ttf') format('truetype'),
          url('../../fonts/averta/averta-semibold.svg#averta-semibold') format('svg');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'averta';
	src: url('../../fonts/averta/averta-bold.eot?#iefix') format('embedded-opentype'),
          url('../../fonts/averta/averta-bold.woff') format('woff'),
          url('../../fonts/averta/averta-bold.woff2') format('woff2'),
					url('../../fonts/averta/averta-bold.ttf') format('truetype'),
          url('../../fonts/averta/averta-bold.svg#averta-bold') format('svg');
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: 'averta';
	src: url('../../fonts/averta/averta-italic.otf') format('opentype');
	font-weight: 400;
	font-style: italic;
}
