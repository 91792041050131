// Global
@import '../../global/global.scss';
@import '../../global/transitions';

// Shared with business
@import '../shared/typography';
@import '../shared/variables';
@import '../shared/header';
@import '../shared/footer';
@import '../shared/nav';
@import '../shared/check-list';
@import '../shared/circle-bg';
@import '../shared/rounded-cta';
@import '../shared/forms';
@import '../shared/datepicker';
@import '../shared/ambassadors';

// Customer specific
// @import './local';
@import './base';
@import './account';
@import './filters';
@import './hero-w-form';
@import './forms';
@import './horizontal-tabs';
@import './misc';
@import './nav';
@import './salon-preview';
@import './skeleton-screens';
@import './tables';
@import './tag-group';
@import './popup-drawer';

@import '../../global/info-bubble';

@import '~v-calendar/lib/v-calendar.min.css';


@import './temp-home';

//Customer only
.c-info-bubble {
    z-index: 10000;
}




.facility-icon {
    border: 1px solid #bbded3;
    background-color: white;
    border-radius: 100%;
    padding: 5px;
    width: 35px;
    height: 35px;
    display: flex;
    line-height: 35px;
    vertical-align: middle;
    color: black;

    i {
        margin-top: 2px;
        margin-left: 1px;
    }
}


.mobile-marker {
    font-size: 0.9rem;
    background-color: black;
    @apply text-teal-400;
    border-radius: 15px;
    padding: 2px 10px;
    line-height: 58px;
    vertical-align: middle;
}

.providers-legend {
    // position: absolute;
    // bottom: 0;
    // left: 10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    font-size: 0.85rem;
    padding: 25px 20px;
    padding-left: 2rem;
    padding-top: 5px;
    pointer-events: none;

    .legend-bg {
        background-color:  #ebebebbb;
        padding: 10px;
        width: auto;

        .provider-key {
            display: flex;
            align-items: center;

            .key-color {
                content: "&nbsp;";
                height: 10px;
                width: 10px;
                margin-right: 5px;
            }
        }
    }

}


.mobile-marker {
    font-size: 0.9rem;
    background-color: black;
    @apply text-teal-400;
    border-radius: 15px;
    padding: 2px 10px;
    line-height: 58px;
    vertical-align: middle;
}

.providers-legend {
    position: absolute;
    bottom: 0;
    left: -10px;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-start;
    font-size: 0.85rem;
    padding: 25px 1.5rem;
    padding-top: 5px;
    pointer-events: none;
    .legend-bg {
        background-color:  #ebebebdd;
        padding: 10px;
        width: auto;
        .provider-key {
            display: flex;
            align-items: center;
            .key-color {
                content: "&nbsp;";
                height: 10px;
                width: 10px;
                margin-right: 5px;
            }
        }
    }

}