.c-stylised-table {
    @apply text-base;
    
    tr {
        @apply bg-white;

        &:nth-of-type(odd) {
            @apply bg-grey-200;
        }
    }

    td {
        @apply py-2 px-4;
    }
} 