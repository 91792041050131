body {
    // padding-bottom: calc(50px + 3rem);
    @apply bg-white;
    // padding-bottom: 50px;
}

.featured-mag-images {
    @apply flex flex-wrap -mt-4 -mx-3 mb-4 ;


    @screen lg {
        @apply mb-0 w-10/12;
    }

    img {
        @apply px-3 mt-4 self-center;
        // max-height: 70px;
        max-width: 150px;
        flex-basis: 33.3%;

    }
}

.customer-header {
    @apply pt-8 pb-4 bg-teal-400;
    max-width: unset;

    a {
        @apply mb-2;
    }

    i {
        @apply text-xl text-black;
    }

    &--has-avatar {
        @apply relative;

        padding-right: calc(2rem + 55px);

        img {
            @apply absolute rounded-full;
            top: 50%;
            right: 2rem;
            transform: translateY(-50%);
            height: 55px;
            width: 55px;
        }
    }
}


.h-screen-minus-nav {
    height: calc(100vh - 50px);
}

.home-splash {
    @apply relative overflow-x-hidden;

    &::before {
        @apply absolute top-0 left-0 opacity-25 bg-white pointer-events-none;
        content: "";
        height: 70%;
        width: 200%;
        border-bottom-left-radius: 100%;
        border-bottom-right-radius: 100%;
        transform: translateX(-25%);
    }

    &::after {
        @apply absolute bottom-0 left-0 w-full bg-cover bg-repeat-x;
        content: "";
        height: 20%;
        background-position: left top;
        background-image: url('/images/backgrounds/brushes.png');
    }
}

.results-scroll-track {
    @apply mb-8 flex;
    flex-direction: column;
    align-items: center;

    // &::before,
    // &::after {
    //     @apply absolute top-0 h-full w-1/12;
    //     content: "";
    //     z-index: 40;
    //     backdrop-filter: blur(1px);
    // }

    // &::before {
    //     @apply left-0;
    // }

    // &::after {
    //     @apply right-0;
    // }
}
