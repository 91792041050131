.c-filter-group {
    @apply -mx-1 z-50;
}

.c-filter-option {
    @apply relative flex items-center justify-between rounded border border-solid border-grey-400 mx-1 text-base text-grey-1000 font-normal capitalize;
    background-color: transparent;

    @screen md {
        @apply border-teal-400 justify-start;
    }

    &--active {
        @apply bg-grey-400 text-white;

        @screen md {
            @apply bg-teal-400 text-grey-1000;
        }
    }


    &__text {
        @apply py-1 pl-2 mr-2;

        &--show-drop-down,
        &:hover {
            .c-filter-option__drop-down {
                @apply opacity-100 pointer-events-auto;
            }
        }
    }

    &__clear {
        @apply py-1 pr-2;

        i {
            @apply relative;
            top: 1px;
        }
    }

    &__drop-down {
        @apply pt-2 cursor-default z-50;
        min-width: 175px;
        transition: 0.4s ease;
        left: -160px;
        @screen md {
            @apply absolute w-auto ml-1;
            top: 100%;
            max-width: 310px;
        }
        @screen lg {
            @apply left-0;
        }

        > div {
            @apply flex flex-col;

            @screen md {
                @apply shadow-lg-allsides p-6 bg-white;
            }
        }
    }
}
