/* Enter and leave animations can use different */
/* durations and timing functions.              */
.popup-slide-enter-active,
.popup-slide-leave-active {
    transition: transform 0.6s ease;
    transform: translateY(0%);
}

.popup-slide-enter,
.popup-slide-leave-to {
    transform: translateY(100%);
}

.fade-slide-enter-active, .fade-slide-leave-active {
    transition: all 0.35s;
    opacity: 1;
    transform: scale(1);
}

.fade-slide-enter, .fade-slide-leave-to {
    opacity: 0;
    transform: scale(1.1);
}

.fade-slide-alt-enter, .fade-slide-alt-leave-to {
    opacity: 0;
    transform: scale(1.1);
}

.fade-slide-alt-enter-active,
.fade-slide-alt-leave-active {
    transition: all 0.6s ease;
    transform: translateY(0%);
    opacity: 1;
}


.slide-in-enter-active,
.slide-in-leave-active {
    transition: transform 0.6s ease;
    transform: translateX(0%);

}

.slide-in-enter,
.slide-in-leave-to {
    transform: translateX(100%);
}


.gallery-fade-enter-active, .gallery-fade-leave-active {
    transition: all 0.6s;
    opacity: 1;
}

.gallery-fade-enter, .gallery-fade-leave-to {
    opacity: 0;
    transition: all 0.6s;
}
