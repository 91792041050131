// .c-account-nav {
//     @apply mb-0;
    
//     &__item {
//         @apply border border-grey-1000 p-2;

//         &:not(:last-of-type) {
//             border-bottom: none;
//         }

//         a {
//             @apply flex items-center justify-between;
//         }
//     }
// }


// .c-account-container {
//     @apply relative;

//     @screen lg {
//         padding-left: 100px;
//     }

//     &::before {
//         @apply absolute left-0 top-0 w-full h-full bg-teal-400 hidden;
//         content: ""; 
//         transform: translateX(-50%);
        
//         @screen lg {
//             @apply block;
//         }
//     }

//     &__inner {
//         @screen lg {
//             @apply relative bg-white;
//             z-index: 2;
//         }

//         @screen lg {
//             padding-left: calc(6rem + 2rem);
//         }

//         @screen xl {
//             padding-left: calc(125px + 2rem);
//         }
//     }
// }

.c-account-image {
    @apply flex justify-center relative;

    div, input {
        z-index: 2;
    }

    .c-image-uploader__image {
        width: 125px;
        height: 125px;
    }

    &__backdrop {
        background-color: #bbded3;
        height: 50%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
}