.c-salon-preview {
    @apply w-6/12 mt-10 text-center text-white;

    @screen md {
        @apply w-4/12;
    }

    @screen lg {
        @apply w-3/12;
    }

    &:last-of-type {
        @apply hidden;

        @screen lg {
            @apply inline-block;
        }
    }


    &:nth-last-of-type(2) {
        @apply hidden;

        @screen md {
            @apply inline-block;
        }
    }

    &__image {
        @apply w-9/12 mx-auto bg-cover bg-no-repeat bg-center rounded-full mb-6;
        padding-top: 75%;
    }

    &__title {
        @apply mb-0 text-white;
    }

    &__location {
        @apply mb-0 font-normal;
    }

    &__rating {
        @apply font-semibold;
    }
}