.btn {
    @apply inline-block py-2 px-4 font-bold rounded cursor-pointer;
    transition: background-color 0.4s ease;

    &:focus {
        @apply outline-none;
    }

    &.disabled {
        // opacity: 0.6;
        cursor: default;
        background-color: desaturate(#bbded3, 20%) !important;

        &:hover {
            background-color: #bbded3 !important;
        }
    }

    &--teal {
        @apply bg-teal-400 text-grey-1000;

        &:hover {
            // You can't use tailwind variables inside sass functions as sass is processed first - so we have to use the variable value
            // Could use current colour but then each button will need an element inside it with the colour style we actually want the button text to be
            background-color: darken(#bbded3, 20%);
        }
    }

    &--copper {
        @apply bg-copper-400 text-white;

        &:hover {
            background-color: darken(#b16d00, 20%);
        }
    }

    &--white {
        @apply bg-white;

        &:hover {
            background-color: darken(#ffffff, 20%);
        }
    }

    &--grey {
        @apply bg-grey-400 text-white;

        &:hover {
            background-color: darken(#999999, 20%);
        }
    }

    &--light-grey {
        @apply bg-grey-200 text-grey-1000;

        &:hover {
            background-color: darken(#f2f2f2, 20%);
        }
    }

    &--dark-grey {
        @apply bg-grey-1000 text-white;

        &:hover {
            background-color: darken(#231f20, 20%);
        }
    }

    &--green {
        @apply bg-green-500 text-white;

        &:hover {
            background-color: darken(#73bfa6, 20%);
        }
    }

    &--red {
        @apply bg-red-400 text-white;

        &:hover {
            background-color: darken(#de8b93, 20%);
        }
    }

    &--fb-blue {
        background-color: #3b5998;
        color: #ffffff;

        &:hover {
            background-color: darken(#3b5998, 20%);
        }
    }

    &--twitter-blue {
        background-color: #1da1f2;
        color: #ffffff;

        &:hover {
            background-color: darken(#1da1f2, 20%);
        }
    }

    &--insta-pink {
        background-color: #cc1984;
        border-color: #cc1984;
        color: #ffffff;

        &:hover {
            background-color: darken(#cc1984, 20%);
        }
    }

    &--outline {
        @apply bg-transparent border text-white;

        &:hover {
            @apply bg-white text-copper-400;
        }

        &.btn--copper {
            @apply border border-copper-400 text-copper-400;

            &:hover {
                @apply bg-copper-400 text-white;
            }
        }
    }

    &--icon {
        @apply flex items-center;

        .btn__icon {
            @apply mr-2 flex-grow-0 flex-shrink-0 text-xl;
            max-height: 20px;
            width: 20px;

            &--sm {
                @apply text-base;
                max-height: 15px;
                width: 15px;
            }
        }
    }

    &--arrow {
        i {
            @apply ml-2;
        }
    }

    &--rounded {
        @apply rounded-full;
    }

    &--lg {
        @apply px-6 text-lg;
    }

    &--add {
        @apply relative rounded-full;
        height: 45px;
        width: 45px;

        .btn__icon {
            @apply absolute text-xl;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
}

.c-btn-group {
    @apply flex flex-wrap items-start -mx-1;

    .btn,
    .cta-btn {
        @apply flex-grow-0 flex-shrink-0 mb-2 mx-1;
    }
}

.cta-btn {
    @apply flex bg-grey-200 uppercase font-bold rounded cursor-pointer;

    &__text,
    &__icon {
        @apply p-3 flex-shrink-0 flex-grow-0 text-white;
    }

    &__icon {
        @apply flex items-center justify-center bg-copper-400;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;

        &--whatsapp {
            background-color: #25d366;
        }

        &--fb {
            background-color: #3b5998;
        }

        img {
            width: 20px;
        }
    }
}
