footer {
    @apply relative bg-grey-1000 text-white text-center pt-12 pb-16;

    @screen md {
        @apply pb-12;
    //     @apply pb-16;
    //     padding-top: 3.5rem;
    }
}

.footer-logo {
    @apply absolute top-0 z-20 pointer-events-none;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 50px;
}