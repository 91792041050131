/* purgecss start ignore */
.c-info-bubble {
    @apply rounded-lg text-black z-50 opacity-0;

    // top: 10px;
    // left: 50%;
    // transform: translateX(-50%);
    animation: hide 8s forwards;

    // Overlay on top, rather than taking up space.
    position: fixed;
    top: 8px;
    left: 8px;

    // The width then needs setting explicitly; margins are 0.5rem on each side so subtract those.
    width: calc(100vw - 16px);

    &--success {
        @apply bg-teal-400 border-2 border-green-500 p-2;
    }

    &--error {
        @apply bg-red-400 border-2 border-red-500 p-2;
    }

    &--warning {
        @apply bg-orange-300 border-2 border-orange-500 p-2;
    }

    &--static {
        @apply opacity-100;
        transform: unset;
        animation: unset;

    }

    &__icon {
        @apply relative text-lg mr-2;
        top: 2px;
    }

    // &.position {

    // }
}

@keyframes hide {
    0% {
        opacity: 0;
        z-index: -1;
    }
    1% {
        z-index: 1000;
    }
    10% {
        opacity: 1;
    }
    95% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        z-index: -1;
    }
}

/* purgecss end ignore */
